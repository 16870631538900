<template>
  <div class="vlt-loading">
    <img v-if="!isEdge" src="@/assets/icons/loading.svg" class="loading-ico" alt="loader" loading="lazy"/>
    <div v-else class="alternateLoader"></div>
  </div>
</template>

<script>
export default {
  name: "appLoader",
  data() {
    return {
      isEdge: false
    };
  },
  created() {
    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    this.isEdge = !isIE && !!window.StyleMedia;
  }
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";

.vlt-loading {
  text-align: center;
  img {
    width: 24px;
  }
}
.alternateLoader {
  border: 2px solid $font-clr-white-2;
  border-radius: 50%;
  border-top: 2px solid $clr-dark-grad-2;
  border-right: 2px solid $clr-dark-grad-2;
  border-bottom: 2px solid $clr-dark-grad-2;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
  margin: 0 auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
